import {Box, Grid, Typography} from '@mui/material';
import useStyles from './style';
//import logo from '../../assets/logo.png';
//import lounge from '../../assets/1920x1080.png';
//import LocationCard from '../../component/Card/LocationCard';
import {Footer} from '../../container';
import newtown from '../../assets/newtown.png';
import colonial from '../../assets/loc-1.png';

import map2 from '../../assets/map-2.png';
import map1 from '../../assets/map-1.png';

import {NavLink} from 'react-router-dom';
import {useState} from 'react';

import egypt from '../../assets/egypt.png';
import LeftRes from '../../component/locres/LeftRes';
import RightRes from '../../component/locres/RightRes';

{
  /*const data = [
  {
    id: 1,
    title: 'Greenpoint, BK ',
    desc: '7 BELL SLIP',
    img: lounge,
    to: 'https://maps.app.goo.gl/yt2epSMUZBSqyqVw8',
  },
  {
    id: 2,
    title: 'Charlottesville, VA',
    desc: ' FOOD HALL 946 GRADY AVE',
    img: lounge,
    to: 'https://maps.app.goo.gl/yt2epSMUZBSqyqVw8',
  },
];*/
}

const Location = () => {
  const classes = useStyles();
  const [showN, setShowN] = useState(false);
  const [showC, setShowC] = useState(false);

  const handleHideShowC = () => {
    setShowC(false);
  };
  const handleShowC = () => {
    setShowC(true);
  };
  const handleHideShowN = () => {
    setShowN(false);
  };
  const handleShowN = () => {
    setShowN(true);
  };
  return (
    <>
      <div className={classes.Amain}>
        <Grid>
          <Typography
            variant='h1'
            sx={{fontSize: {xl: '3.8rem', lg: '3.8rem', md: '3.8 rem', xs: '2.8rem'}}}>
            Eleva Coffee Lounge
            <br /> Locations
          </Typography>
        </Grid>
        <Grid item lg={12} xs={12} sx={{paddingTop: {lg: '5%'}}}>
          <Grid container lg={12} xs={12} sx={{width: '100%'}}>
            <Box
              sx={{
                display: {xl: 'flex', lg: 'flex', md: 'block', sm: 'block', xs: 'content'},
                textAlign: 'center',
                justifyContent: 'center',
              }}>
              {/*Responsive*/}
              <Grid
                lg={6}
                sx={{
                  marginLeft: '-100px',
                  display: {xl: 'none', lg: 'none', md: 'flex', sm: 'flex', xs: 'flex'},
                }}>
                <LeftRes />
              </Grid>

              <Grid
                lg={6}
                sx={{
                  marginLeft: '-100px',
                  display: {xl: 'flex', lg: 'flex', md: 'none', sm: 'none', xs: 'none'},
                }}>
                <NavLink
                  onMouseOver={handleShowC}
                  onMouseLeave={handleHideShowC}
                  to='https://maps.app.goo.gl/yt2epSMUZBSqyqVw8'
                  style={{
                    textDecoration: showC ? 'none' : 'none',
                  }}>
                  <Box sx={{display: 'flex', paddingTop: '10%', paddingRight: '50px'}}>
                    <Grid lg={6}>
                      <Box
                        component='img'
                        src={map1}
                        sx={{
                          width: {
                            xl: '100%',
                            lg: '110%',
                            md: '70%',
                            sm: '80%',
                            xs: '90%',
                            marginTop: '-25px',
                            zIndex: 5,
                            marginLeft: '20%',
                          },
                        }}
                      />
                    </Grid>

                    <Grid lg={6}>
                      <Box sx={{paddingLeft: '20px', marginTop: '5%'}}>
                        <Typography variant='h4' sx={{color: '#e7c978'}}>
                          Colonial,VA
                        </Typography>
                        {showC ? (
                          <>
                            <Typography variant='h6' sx={{color: '#fff'}}>
                              click to view
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography variant='h6' sx={{color: '#fff'}}>
                              Show Me Direction
                            </Typography>
                          </>
                        )}
                      </Box>
                      <Box
                        component='img'
                        src={colonial}
                        sx={{
                          width: {xl: '100%', lg: '120%', md: '70%', sm: '80%', xs: '90%'},
                          marginRight: '-50px',
                          zIndex: 10,
                          position: 'relative',
                          marginTop: '-20%',
                        }}
                      />
                    </Grid>
                  </Box>
                </NavLink>
              </Grid>
              <Grid
                lg={6}
                sx={{
                  marginLeft: '-100px',
                  display: {xl: 'none', lg: 'none', md: 'flex', sm: 'flex', xs: 'flex'},
                }}>
                <RightRes />
              </Grid>
              <Grid
                lg={6}
                sx={{
                  marginLeft: '-100px',
                  display: {xl: 'flex', lg: 'flex', md: 'none', sm: 'none', xs: 'none'},
                }}>
                <NavLink
                  onMouseOver={handleShowN}
                  onMouseLeave={handleHideShowN}
                  to='https://maps.app.goo.gl/yt2epSMUZBSqyqVw8'
                  style={{
                    textDecoration: showN ? 'none' : 'none',
                    backdropFilter: showN ? 'blur(3px)' : 'none',
                  }}>
                  <Box sx={{display: 'flex'}}>
                    <Grid lg={6}>
                      <Box
                        component='img'
                        src={newtown}
                        sx={{
                          width: {xl: '100%', lg: '110%', md: '70%', sm: '80%', xs: '90%'},
                          marginRight: '-150px',
                          zIndex: 10,
                          position: 'relative',
                        }}
                      />
                      <Box sx={{paddingLeft: '100px', marginTop: '80px'}}>
                        <Typography variant='h4' sx={{color: '#e7c978'}}>
                          Newtown,VA
                        </Typography>
                        {showN ? (
                          <>
                            <Typography variant='h6' sx={{color: '#fff'}}>
                              click to view
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography variant='h6' sx={{color: '#fff'}}>
                              Show Me Direction
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Grid>
                    <Grid lg={6}>
                      <Box
                        component='img'
                        src={map2}
                        sx={{
                          width: {
                            xl: '100%',
                            lg: '110%',
                            md: '70%',
                            sm: '80%',
                            xs: '90%',
                            paddingTop: '100px',
                            zIndex: 5,
                            marginLeft: '-50%',
                          },
                        }}
                      />
                    </Grid>
                  </Box>
                </NavLink>
              </Grid>
            </Box>
            <Grid item lg={6} xs={12}>
              {' '}
              <Grid
                lg={6}
                sx={{
                  paddingBottom: '30%',
                  display: {xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'flex'},
                  marginLeft: {xl: '30%', lg: '10%', md: '-10%', sm: '-10%', xs: '-10%'},
                }}>
                <Box
                  component='img'
                  src={egypt}
                  sx={{
                    width: {
                      xl: '100%',
                      lg: '100%',
                      md: '70%',
                      sm: '80%',
                      xs: '70%',
                      zIndex: 5,
                    },
                  }}
                />
              </Grid>
              <Grid lg={12} xs={12}>
                <Box
                  sx={{
                    paddingLeft: {xl: '40%', lg: '40%', md: '40%', sm: '60%', xs: '50%'},
                    marginTop: {xl: '-30%', lg: '-40%', md: '-40%', sm: '-40%', xs: '-40%'},
                  }}>
                  <Typography variant='h4' sx={{color: '#e7c978'}}>
                    Cairo,Egypt
                  </Typography>
                  <>
                    <Typography variant='h6' sx={{color: '#fff'}}>
                      Opening Soon !
                    </Typography>
                  </>
                </Box>
              </Grid>
            </Grid>
            <Grid lg={6}>
              <Box
                sx={{
                  paddingLeft: {xl: '25%', lg: '10%', md: '10%', sm: '10%', xs: '10%'},
                  paddingRight: {xl: '20%', lg: '20%', md: '10%', sm: '10%', xs: '10%'},
                  paddingTop: '15%',
                }}>
                <Typography
                  variant='h3'
                  sx={{
                    textAlign: {xl: 'left', lg: 'left', md: 'center', sm: 'center', xs: 'center'},
                    color: '#e7c978',
                    fontSize: {
                      xl: '3.8rem',
                      lg: '3.5rem',
                      md: '2.8rem',
                      sm: '2.8rem',
                      xs: '2.8rem',
                    },
                  }}>
                  Stay tuned for more details!
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer color1='#2b3836' color2='#2b3836' />
    </>
  );
};

export default Location;
