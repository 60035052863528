import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import {NavLink} from 'react-router-dom';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import MenuIcon from '@mui/icons-material/Menu';
//import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import useStyle from './Style';
import Logo from '../../assets/logo.png';
const ResHeader = () => {
  const classes = useStyle();

  const [state, setState] = React.useState({
    left: false,
  });
  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({...state, [anchor]: open});
  };

  const list = anchor => (
    <Box
      sx={{width: 250}}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      {/*<Box sx={{display: 'flex', ml: '20px'}}>
        <AutoAwesomeMotionIcon fontSize='small' color='inherit' />
        <Typography
          variant='h6'
          noWrap
          component='a'
          sx={{
            mr: 2,
            display: {xs: 'Flex', md: 'none'},
            fontFamily: 'inherit',
            fontWeight: 500,
            letterSpacing: '.05rem',
            color: '#3E608F',
            textDecoration: 'none',
            textAlign: 'Left',
            ml: '15px',
            fontSize: '18px',
          }}>
          Main
        </Typography>
      </Box>*/}
      {/*  <Box sx={{}}>
        <ListItemButton href='/'>
          <ListItemIcon sx={{ml: '10px'}}>
            <ArrowCircleRightIcon fontSize='small' color='inherit' />
          </ListItemIcon>
          <ListItemText fontSize='15px' primary='Home' sx={{ml: '-25px', color: '#fff'}} />
        </ListItemButton>
      </Box>*/}
      <Divider />
      <Box sx={{}}>
        <ListItemButton href='/eleva-lounge-menu'>
          <ListItemIcon sx={{ml: '10px'}}>
            <ArrowCircleRightIcon fontSize='small' color='inherit' />
          </ListItemIcon>
          <ListItemText fontSize='15px' primary='Menu' sx={{ml: '-25px', color: '#fff'}} />
        </ListItemButton>
      </Box>
      <Divider />
      {/*
      <Box sx={{}}>
        <ListItemButton href='/eleva-coffee-app'>
          <ListItemIcon sx={{ml: '10px'}}>
            <ArrowCircleRightIcon fontSize='small' color='inherit' />
          </ListItemIcon>
          <ListItemText fontSize='15px' primary='Eleva App' sx={{ml: '-25px', color: '#fff'}} />
        </ListItemButton>
      </Box>*/}
      <Divider />
      {/* <Box sx={{}}>
        <ListItemButton href='/contact-us'>
          <ListItemIcon sx={{ml: '10px'}}>
            <ArrowCircleRightIcon fontSize='small' color='inherit' />
          </ListItemIcon>
          <ListItemText fontSize='15px' primary='Contact' sx={{ml: '-25px', color: '#fff'}} />
        </ListItemButton>
      </Box>*/}
      <Divider />
      <Box sx={{}}>
        <ListItemButton href='/eleva-lounge-locations'>
          <ListItemIcon sx={{ml: '10px'}}>
            <ArrowCircleRightIcon fontSize='small' color='inherit' />
          </ListItemIcon>
          <ListItemText fontSize='15px' primary='Locations' sx={{ml: '-25px', color: '#fff'}} />
        </ListItemButton>
      </Box>
      <Divider />
      {/* <Box sx={{}}>
        <ListItemButton href='/franchise-with-eleva'>
          <ListItemIcon sx={{ml: '10px'}}>
            <ArrowCircleRightIcon fontSize='small' color='inherit' />
          </ListItemIcon>
          <ListItemText fontSize='15px' primary='Franchise' sx={{ml: '-25px', color: '#fff'}} />
        </ListItemButton>
      </Box>*/}
    </Box>
  );
  return (
    <>
      <div>
        <AppBar
          position='fixed'
          sx={{
            backgroundColor: 'transparent',
            backdropFilter: 'blur(4px)',
            zIndex: '1000',
            height: {xl: '80px', lg: '110px', md: '100px', sm: '100px', xs: '100px'},
            padding: '5px',
          }}>
          <Box sx={{display: 'flex', paddingX: '20px', paddingY: '10px'}}>
            <Grid xl={6} xs={6}>
              <Box>
                {['left'].map(anchor => (
                  <React.Fragment key={anchor}>
                    <IconButton
                      size='large'
                      edge='start'
                      color='inherit'
                      aria-label='menu'
                      sx={{mr: 2}}
                      onClick={toggleDrawer(anchor, true)}>
                      <MenuIcon sx={{color: '#be974f', fontSize: '2.5rem'}} />
                    </IconButton>
                    <Drawer
                      sx={{backdropFilter: 'blur(4px)'}}
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      PaperProps={{
                        sx: {
                          backgroundColor: '#be974f',
                          backdropFilter: 'blur(4px)',
                          opacity: '80%',
                          width: '30vh',
                        },
                      }}>
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            margin: '20px',
                            paddingBottom: '20px',
                            paddingTop: '10px',
                          }}>
                          <Box
                            sx={{
                              marginTop: '10px',
                              marginLeft: '0px',
                              marginRight: '10%',
                              display: {md: 'flex', sm: 'flex', xs: 'flex'},
                            }}>
                            <NavLink to='/' style={{textDecoration: 'none'}}>
                              {/*<img alt="" src={logo} width="300px" />*/}
                              <Typography
                                variant='h1'
                                sx={{
                                  color: '#fff',
                                  fontSize: {
                                    xl: '23px',
                                    lg: '23px',
                                    md: '23px',
                                    sm: '23px',
                                    xs: '20px',
                                  },
                                  fontWeight: 'bold',
                                }}>
                                Eleva Coffee Lounge
                              </Typography>
                              <Divider />
                              <Typography
                                variant='h1'
                                sx={{
                                  color: '#fff',
                                  fontSize: {
                                    xl: '15px',
                                    lg: '15px',
                                    md: '15px',
                                    sm: '15px',
                                    xs: '15px',
                                  },
                                }}>
                                When Coffee Loves You
                              </Typography>
                            </NavLink>
                          </Box>
                        </Box>
                        {list(anchor)}
                      </Box>
                    </Drawer>
                  </React.Fragment>
                ))}
              </Box>
            </Grid>
            <Grid xl={6} xs={6} sx={{textAlign: 'right'}}>
              <Box>
                <NavLink className={classes.Nav} to='/'>
                  <Box
                    component='img'
                    sx={{width: '120px'}}
                    src={Logo}
                    alt='eleva-coffee-lounge-logo'
                  />
                </NavLink>
              </Box>
            </Grid>
          </Box>
        </AppBar>
      </div>
    </>
  );
};

export default ResHeader;
