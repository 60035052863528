import {Box, Grid, Typography} from '@mui/material';
import mount from '../../assets/mount.png';
const Footer = props => {
  const {color1, color2} = props;
  return (
    <>
      <Box
        sx={{
          backgroundColor: `${color1}`,
          color: '#fff',
        }}>
        <Box sx={{paddingTop: '0'}}>
          <Grid lg={12}>
            <Box
              component='img'
              src={mount}
              sx={{
                width: '100%',
              }}
            />
            <Grid sx={{textAlign: 'center', paddingY: '20px', backgroundColor: `${color2}`}}>
              <Typography variant='h5'>all rights reserved © 2024</Typography>
            </Grid>{' '}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
