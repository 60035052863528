import {makeStyles} from '@mui/styles';

export default makeStyles(() => ({
  HeaderMain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#fff',
    paddingLeft: '120px',
    paddingRight: '120px',
    paddingTop: '32px',
    paddingBottom: '32px',
    gap: '1117px',
    position: 'absolute',
    zIndex: '5',
  },
  HeaderContent: {
    height: '28px',
    top: '70px',
    left: '20px',
    gap: '100px',
    paddingTop: '45px',
  },
  HeaderLogoContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    paddingRight: '20%',
    marginTop: '-70px',
  },
  logo: {
    display: 'flex',
    width: '30%',
  },
  Nav: {
    textDecoration: 'none',
    color: '#fff',
  },
}));
