import {makeStyles} from '@mui/styles';

export default makeStyles(() => ({
  main: {
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '-10px',
    objectFit: 'fill',
    backgroundColor: '#2b3836',
  },
}));
